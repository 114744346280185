<template>
    <div>
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-space-between">
                    <div style="padding: 18px 12px">
                        <div class="text-2xl"><i class="mdi mdi-application-import"></i> ລາຍການພັດສະດຸໃນສາງ</div>
                    </div>
                    <v-breadcrumbs
                        class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
                        :items="$route.meta.breadcrumb"
                        divider=">"
                        large
                    />

                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <!-- <v-card-title>
                        <v-row>
                            <v-col cols="12">
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider> -->
                    <v-card-actions>
                        <v-btn color="green" outlined dark class="mx-1" @click="$router.push({name: 'wharehouseadd', params: { title: 'ຮັບພັດສະດຸລົງສາງ', icon: 'mdi mdi-plus-circle-outline'}})" v-if="adddata">
                            <i class="mdi mdi-plus-circle-outline"></i> 
                            <span class="d-none d-md-block d-lg-block d-xl-block">ຮັບພັດສະດຸລົງສາງ</span>
                        </v-btn>  
                        <v-btn color="primary" outlined dark class="mx-1" @click="getListPackage()">
                            <i class="mdi mdi-reload"></i> 
                            <span class="d-none d-md-block d-lg-block d-xl-block">ຮີໂຫລດ</span>
                        </v-btn>       
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="ຄົ້ນຫາ"
                            single-line
                            hide-details
                            style="max-width: 250px"
                        />
                    </v-card-actions>
                    <v-data-table
                        :headers="headers"
                        :items="listdata"
                        :search="search"
                        :items-per-page="10"
                        class="elevation-1"
                        item-class="align-top"
                        :loading="$store.state.auth.loading2"
                        loading-text="ກຳລັງໂຫລດ...ກະລຸນາລໍຖ້າ"
                        fixed-header
                        height="530px"
                        :footer-props="{
                            'items-per-page-options': [10, 50, 100, -1],
                            showFirstLastPage: true,
                            'items-per-page-text': 'ຈຳນວນທີ່ສະແດງ:'
                        }"
                        dense
                    >
                        
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="rows in items" :key="rows.id">
                                    <td class="text-center align-top whitespace-nowrap">
                                        <!-- <v-btn
                                            class="mr-1"
                                            color="primary"
                                            fab
                                            small
                                            dark
                                            text 
                                            @click="printBarcode(rows)"
                                        >
                                            <v-icon>mdi-printer</v-icon>
                                        </v-btn> -->
                                    </td>
                                    <td class="text-center align-top whitespace-nowrap">{{ rows.rno }}</td>
                                    <td class="text-center align-top whitespace-nowrap">{{ rows.track_code }}</td>
                                    <td class="text-left align-top whitespace-nowrap">{{ rows.pack_name }}</td>
                                    <td class="text-center align-top whitespace-nowrap">{{ $helper.formatNumber(rows.pack_amount, 0) }}</td>
                                    <td class="text-right align-top whitespace-nowrap">{{ $helper.formatNumber(rows.pack_price, 0) }}</td>
                                    <td class="text-left align-top" style="min-width: 250px; max-width: 250px;">{{ rows.pack_remark }}</td>
                                    <td class="text-center align-top whitespace-nowrap pt-1">
                                        <v-chip
                                            color="success"
                                            dark
                                            small
                                            outlined
                                            label
                                        >
                                            {{ rows.to_branch + '-' + rows.to_branch_name }}
                                        </v-chip>
                                    </td>
                                    <td class="text-center align-top whitespace-nowrap">
                                        <div>{{ rows.form_customer_name + ' ' + rows.form_customer_tel }}</div>
                                        <!-- <div>{{ '(' + rows.form_customer_tel + ')' }}</div> -->
                                    </td>
                                    <td class="text-center align-top whitespace-nowrap">
                                        <div>{{ rows.to_customer_name + ' ' + rows.to_customer_tel }}</div>
                                        <!-- <div>{{ rows.to_customer_name }}</div>
                                        <div>{{ '(' + rows.to_customer_tel + ')' }}</div> -->
                                    </td>
                                    <td class="text-center align-top whitespace-nowrap">
                                        {{ $helper.formatFontDate(rows.added_date) }} <br> {{ $helper.formatFontTime(rows.added_date) }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                search: "",
                headers: [
                    {
                        text: 'ຕົວເລືອກ',
                        align: 'center',
                        sortable: false,
                        value: 'option',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: '#',
                        align: 'center',
                        sortable: false,
                        value: 'rno',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ເລກທີຕິດຕາມພັດສະດຸ',
                        align: 'center',
                        sortable: true,
                        value: 'track_code',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ຊື່ພັດສະດຸ',
                        align: 'start',
                        sortable: true,
                        value: 'pack_name',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ຈຳນວນ',
                        align: 'center',
                        sortable: true,
                        value: 'pack_amount',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ລາຄາ',
                        align: 'end',
                        sortable: true,
                        value: 'pack_price',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ລາຍລະອຽດພັດສະດຸ',
                        align: 'start',
                        sortable: true,
                        value: 'pack_remark',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ຂໍ້ມູນສາຂາປາຍທາງ',
                        align: 'center',
                        sortable: false,
                        value: 'to_branch',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ຂໍ້ມູນຜູ້ຝາກ',
                        align: 'center',
                        sortable: true,
                        value: 'form_customer',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ຂໍ້ມູນຜູ້ຮັບ',
                        align: 'center',
                        sortable: true,
                        value: 'to_customer',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                    {
                        text: 'ວັນທີສ້າງບີນ',
                        align: 'center',
                        sortable: true,
                        value: 'pack_status',
                        class: 'amber lighten-1 black--text whitespace-nowrap body-2'
                    },
                ],
                listdata: [],
                adddata: false,
                expanded: [],
            }
        },
        async created() {
            await this.getListPackage();
        },
        methods: {
            async getListPackage(){
                const formdata = await {
                    action: "listpackageinstock",
                    route: this.$route.name,
                    id: '',
                    start: '',
                    end: '',
                }
                this.listdata = await [];
                await this.$store.commit("auth/setLoading2", true);
                const result = await this.$store.dispatch("manage/getManagePackage", formdata);
                await this.$store.commit("auth/setLoading2", false);
                if(result.success){
                    this.listdata = await result.data;
                }
                this.adddata = await result.r_add;
            },
            async printBarcode(data){
                console.log(data)
                this.$swal.fire({
                    title: 'Oops',
                    text: 'ກຳລັງພັດທະນາ',
                    icon: 'warning',
                });
            }
        },
    }
</script>


<style>
    .align-top{
        vertical-align: top;
    }
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .font12{
        font-size: 10pt !important;
    }
</style>